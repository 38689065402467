@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("assets/css/normalize..css");

@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-leicht.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-leicht-kursiv.woff2) format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-buch.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-kraftig.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-kraftig-kursiv.woff2) format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-halbfett.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-dreiviertelfett.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-fett.woff2) format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Sohne;
  src: url(assets/fonts/sohne/sohne-web-extrafett.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@layer components {
  .bg-hero {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21818&authkey=%21AC4C5zWiJr0S5A4&width=5472&height=2809")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .bg-carrot {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21814&authkey=%21AH1mOfvhHrImzQw&width=6000&height=4000")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .bg-vision {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21829&authkey=%21ADspzYhb0bZbdz8&width=8272&height=5445")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .bg-mission {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21832&authkey=%21AObAWsRY-PTIWgQ&width=4288&height=2848")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .bg-about {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21822&authkey=%21AHQcn8znd93YhqY&width=2500&height=1667")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .bg-service {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21819&authkey=%21AKYpbf3bvpMrna0&width=3840&height=5760")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  .bg-contact {
    background: url("https://onedrive.live.com/embed?resid=B8295C03D418D49B%21825&authkey=%21ALju-nz2T4XXors&width=3840&height=5760")
      no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  @media screen and (max-width: 600px) {
    .bg-mission,
    .bg-vision,
    .bg-carrot,
    .bg-hero,
    .bg-about,
    .bg-service,
    .bg-contact {
      background-attachment: scroll;
    }
  }
}

/*
* {
  cursor: url(assets/icons/cursormouse2.svg), auto !important;
  scroll-margin-top: 1rem;
}
*/

::-webkit-scrollbar {
  height: 100%;
  width: 0.75rem;
  background-color: initial;
}
body::-webkit-scrollbar {
  --tw-bg-opacity: 1;
  background-color: rgb(8 8 9 / var(--tw-bg-opacity));
}
::-webkit-scrollbar-track {
  background-color: initial;
}
::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  border: 0.125rem solid #0000;
  background-clip: content-box;
  background-color: #e9e9e9;
}
::-webkit-scrollbar-button {
  display: none;
}

html {
  font-family: Sohne, sans-serif;
  font-feature-settings: normal;
  font-variation-settings: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}

body {
  background-color: "#ebebeb";
}

.border-cus {
  border-radius: 0.375rem !important;
}

.contact-cta {
  background-image: url("");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.quote-cta {
  background-image: url("");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
